
import React from 'react'
import { useDispatch } from 'react-redux'
import { setGotoElement } from '@reducers/contentReducer'
import { contentCode } from '@data/contentCode.data'
import PolicyContent from './components/PolicyContent'
import ContentDataHook from '@hooks/ContentData.hook'
import Loading from '@components/Loading'
/**
 * 
 * @returns Renders the privacy policy page
 */
export default function CookieStatement() {

  // const dispatch = useDispatch()
  // Using the useSelector hook to access the contents from the Redux store
  // const contents = useSelector(state => state.content.contents)

  // Finding the privacy policy content from the contents array
  // const policy = contents.find(item => item.code === 'privacy')
  // const terms = contents.find(item => item.code === 'terms')
  const { isPending, error, data: cookiePolicy } = ContentDataHook(contentCode.COOKIE_STATEMENT)

  if (isPending) return <Loading />

  // console.log('about', data)

  if (error) return 'Cookie Policy Page: An error has occurred: ' + error.message

  return (
    <>
      <div className='p-10 smsize:p-5'>
        <div className='text-left'>
          {/* <h3>{cookieStatement?.title}</h3> */}
          <p dangerouslySetInnerHTML={{ __html: cookiePolicy?.content }}></p>
        </div>
      </div>
    </>
  )
}
