import React, { useRef } from 'react'
import GiftGuideProducts from './components/GiftGuideProducts'
import GiftCards from './components/GiftCards'

export default function StoreGiftGuide({
    isMobile,
    business,
}) {

    const productRef = useRef(null)

    return (
        <div className='w-full'>
            <div className='m-auto w-full max-w-[1280px] flex justify-between gap-10 smsize:max-w-full smsize:flex-col smsize:p-5 smsize:gap-5'>
                <div ref={productRef} id='store-main-gift-guide' className='flex-2 w-full py-[35px] px-[65px] flex flex-col gap-3 smsize:p-5'>
                    <GiftCards business={business} isMobile={isMobile} />
                    <GiftGuideProducts business={business} isMobile={isMobile} />
                </div>
            </div>
        </div>
    )
}
