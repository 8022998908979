import React from 'react'
import './GiftGuide.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import PhoneNumber from './components/PhoneNumber'
import Address from './components/Address'
import Price from './components/Price'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import GiftGuideTemplate from './components/GiftGuideTemplate'

export default function GiftGuide1b({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )

  return (
    <GiftGuideTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='relative flex flex-col gap-3 px-10 py-5 smsize:p-5'>
        <img
          className='absolute top-5 right-10 z-0 w-[300px] opacity-50 smsize:right-5 smsize:w-[200px]'
          src='/gift-guide/snow-flake-page4.png'
          alt='Snow flake'
        />
        <div className='flex gap-5 py-3'>
          <Logo
            logo={business?.logo}
            name={business?.name}
            storeLink={storeLink}
          />
          <div className='flex flex-col gap-3 text-left'>
            <h1 className='z-10 whitespace-normal break-normal break-words pb-2 text-[45px] font-bold leading-7 text-[#541b1f] smsize:text-[30px]'>
              {business?.name}
            </h1>
            <Address business={business} />
            <PhoneNumber business={business} />
          </div>
        </div>
        <p
          className='w-[90%] text-left text-[20px] font-light leading-7 line-clamp-4 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'
          dangerouslySetInnerHTML={{
            __html: business?.shortDesc || business?.description,
          }}
        />
      </div>
      <div className='flex w-full items-center justify-start gap-5 px-10 py-5 smsize:justify-center smsize:p-0'>
        <ShopNowButton
          storeLink={storeLink}
          bgColor='#520a0a'
          borderColor='#520a0a'
          textColor='#fffdf6'
        />
        <GiftCardButton
          giftCardLink={business?.giftCardLink}
          storeLink={storeLink}
          bgColor='#fffdf6'
          borderColor='#520a0a'
          textColor='#520a0a'
        />
      </div>
      <div className='flex w-full flex-col justify-start gap-3 py-5 px-10 text-left text-[#202020] smsize:p-5'>
        <ItemTitle name={business?.items[0]?.name} />
        <p
          className='w-[80%] leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{
            __html:
              business?.items[0]?.gift_guide_text ||
              business?.items[0]?.description,
          }}
        />
        <Price
          salePrice={business?.items[0]?.salePrice}
          priceRange={business?.items[0]?.gift_guide_price_range}
        />
        <SeeMoreDetails item_id={business?.items[0]?._id} />
      </div>
      <div className='flex w-full'>
        <div className='w-[50px] flex-shrink-0 bg-[#520a0a] smsize:hidden'>
          {' '}
        </div>
        <ItemImage
          image={business?.items[0]?.image}
          name={business?.items[0]?.name}
          width='100%'
          extraCss={
            'max-h-[400px] w-full object-cover smsize:h-[450px] smsize:max-h-[450px]'
          }
        />
      </div>
    </GiftGuideTemplate>
  )
}
