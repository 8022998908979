/**
 * Copyright Fitzba Technologies Inc. 2023
 * 
 * This module exports a React component named 'ItemDeliveryIconList'. This component displays the delivery options for a specific item.
 * It includes icons and labels for pickup, delivery, and shipping options. The availability of each option is indicated by tooltips.
 * 
 * The component takes three props: 'business', 'deliveryTypes', and 'extraClass'. 'business' is the business selling the item. 'deliveryTypes' is an array of delivery options available for the item.
 * 'extraClass' is a string of class names to apply to the component.
 * 
 * @author Yang Ming, James Nguyen
 * @version January 18th, 2024
 */


import React from 'react'
import ItemDeliveryIcon from './ItemDeliveryIcon'
import { HiOutlineLocationMarker, HiOutlineTruck } from 'react-icons/hi'
import { FiPackage } from 'react-icons/fi'
import { IoChatbubbleEllipsesOutline } from "react-icons/io5"
import StoreGoogleMap from './StoreGoogleMap'
/**
 * ItemDeliveryIconList is a component that displays the delivery options for a specific item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.business - The business selling the item.
 * @param {Array} props.deliveryTypes - An array of delivery options available for the item.
 * @param {string} props.extraClass - A string of class names to apply to the component.
 * @returns {JSX.Element} The 'ItemDeliveryIconList' component.
 */
export default function ItemDeliveryIconList({
    business,
    deliveryTypes,
    extraClass,
    setIsOpenMessage
}) {
    return (
        <div className={`flex flex-row gap-2 sm:gap-5 ${extraClass}`}>
            <div id='pickup-icon' className='flex flex-col items-center justify-center text-center cursor-pointer'>
                <StoreGoogleMap
                    business={business}
                    content={
                        <div className={`w-[48px] h-[48px] rounded-full border-2 border-opacity-50 p-2 border-[#7B7B7B]`}>
                            <HiOutlineLocationMarker
                                size={27.5}
                                color={'#7B7B7B'}
                            />
                        </div>
                    } />
                <span style={{ color: '#0B6186', fontWeight: 300 }}>
                    Pickup
                </span>
            </div>
            {business?.isDelivery &&
                <ItemDeliveryIcon
                    text='Delivery'
                    Icon={HiOutlineTruck}
                    deliveryTypes={['Delivery']}
                    tip='Delivery'
                />
            }
            {business?.isShipping &&
                <ItemDeliveryIcon
                    text='Shipping'
                    Icon={FiPackage}
                    deliveryTypes={['Shipping']}
                    tip='Shipping'
                />
            }
            <div
                className='flex flex-col items-center justify-center text-center cursor-pointer'
                onClick={() => setIsOpenMessage(true)}
            >
                <div className={`w-[48px] h-[48px] rounded-full border-2 border-opacity-50 p-2 border-[#7B7B7B]`}>
                    <IoChatbubbleEllipsesOutline
                        size={25}
                        color={'#7B7B7B'}
                    />
                </div>
                <span style={{ color: '#0B6186', fontWeight: 300 }}>
                    Message
                </span>
            </div>
        </div>
    )
}
