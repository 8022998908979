import React from 'react'
import { Link } from 'react-router-dom'
import { getStoreLink } from '@utils/Helper'

export default function GiftCardsItem({
  item
}) {
  let priceText = ''
  if (item?.min && !item?.max) {
    priceText = `$${item?.min}`
  } else if (!item?.min && item?.max) {
    priceText = `$${item?.max}`
  } else if (item?.min && item?.max) {
    priceText = `$${item?.min} - $${item?.max}`
  }
  const storeLink = getStoreLink(item?.business?.shortLink, item?.business?.city, item?.business?.province)
  return (
    <div className='flex flex-col gap-1 w-[300px] smsize:w-full smsize:h-[300px]'>
      <a href={item?.link} target='_blank' rel="noreferrer" className='relative border rounded-lg overflow-hidden flex items-center justify-center'>
        <img src={item?.image} alt={item?.title} className='object-cover w-[300px] h-[200px] smsize:w-full smsize:h-[300px]' />
        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-20 p-2 flex justify-between">
          <h2 className="text-white text-lg font-semibold">{item?.title}</h2>
          {priceText && <h2 className="text-white font-light">{priceText}</h2>}
        </div>
      </a>
      <Link to={storeLink}>{item?.business?.name}</Link>
    </div>
  )
}
