import React from 'react'
import { Dropdown } from 'antd'
import { BarsOutlined } from '@ant-design/icons'

export default function DropDown({
    selectItems,
    index,
    setIndex,
    extraCass
}) {
    // return null
    if (!selectItems || selectItems.length === 0 ) return null
    return (
        <Dropdown
            onOpenChange={(e)=>{
                console.log('e', e)
            }}
            menu={{
                items: selectItems,
            }}
            trigger={['click']}
            placement='left'
            arrow
        >
            <div className={`cursor-pointer text-[30px] flex items-center justify-center text-white ${extraCass}`}>
                <BarsOutlined />
            </div>
        </Dropdown>
    )
}
