import React, { useRef, useState } from 'react'
import './GiftGuide.css'
import PageNumbers from './components/PageNumbers'
import { Tour } from 'antd'
import { useSelector } from 'react-redux'
import LeftAndRightBtns from './components/LeftAndRightBtns'
import DropDown from './components/DropDown'

export default function GiftGuide1({
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  index,
  setIndex,
  selectItems,
}) {
  const isMobile = useSelector(state => state.content.isMobile)
  const pageNumberRef = useRef(null)
  const [open, setOpen] = useState(isMobile && !localStorage.getItem('gift-guide-tour'))
  const steps = [
    {
      // title: 'Welcome',
      description: 'You can swipe left or right to navigate through the pages.',
      cover: (
        <img
          alt='hand-finger-left-right'
          src='/gift-guide/hand-finger-left-right.webp'
        />
      ),
      // description: 'left',
      target: null,
      style: isMobile ? { maxWidth: '95%' } : '',
    },
  ]

  return (
    <div className='relative m-auto flex h-[1280px] w-[768px] flex-col items-center gap-3 bg-[#520a0a] py-5 px-10 text-white shadow-lg smsize:h-full smsize:w-full smsize:px-5'>
      <DropDown selectItems={selectItems} index={index} setIndex={setIndex} 
        extraCass={'absolute top-2 right-2 rounded-full bg-[#202020] text-[#ffffff] h-[40px] w-[40px]'}
      />
      <LeftAndRightBtns
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        currentPage={currentPage}
        totalPage={totalPage}
      />
      <Tour
        open={open}
        onClose={() => {
          localStorage.setItem('gift-guide-tour', 'true')
          setOpen(false)
        }}
        steps={steps}
        style={{ width: '100%' }}
      />
      <PageNumbers
        currentPage={currentPage}
        totalPage={totalPage}
        pageNumberRef={pageNumberRef}
      />
      <h4 className='text-[20px] uppercase leading-5 text-[#faf5ea] text-opacity-70'>
        December 2024
        <br />
        COCHRANE, ALBERTA
      </h4>
      <div className='mt-3 flex w-[250px] items-center justify-center rounded-full border-2 p-2'>
        <a href='https://www.fitzba.com' target='_blank' rel='noreferrer'>
          <img width={150} src='/logo-white.png' alt='Fitzba.com' />
        </a>
      </div>
      <div className='my-6 mx-5 flex w-full flex-col items-center justify-center border-b-[3px] pb-4 uppercase leading-[100px] smsize:my-3 smsize:pb-2'>
        {/* <h1 className='text-[120px]'>Holiday</h1> */}
        <img
          className='w-[550px] smsize:w-[320px]'
          src='/gift-guide/holiday.png'
          alt='Holiday'
        />
        <p className='text-[90px] font-light text-[#faf5ea] smsize:text-[55px] smsize:leading-[65px]'>
          GIFT GUIDE
        </p>
      </div>
      <div
        style={{
          backgroundImage: 'url(/gift-guide/gift-guide-bg.png)',
        }}
        className='flex h-[800px] w-full flex-col justify-between bg-cover bg-center bg-no-repeat py-5 smsize:h-[600px]'
      >
        <div className='text-right text-[20px] text-[#fffdf6] text-opacity-80'>
          Give Better, Feel Better: <br />
          Local Gift Ideas
        </div>
        <div className='flex w-full items-start text-left text-[20px] text-[#fffdf6] text-opacity-80'>
          Gifts That Wow: <br />
          Memorable Local Finds
        </div>
      </div>
      {/* <div className='w-full text-left text-[20px] font-light text-[#faf5ea] text-opacity-70'>
        IN PARTNERSHIP WITH
      </div> */}
    </div>
  )
}
