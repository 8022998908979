/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 *  Get the app pop-up window
 *
 * @author Yang Ming
 * @version Feb 27, 2024
 */

import React, { useEffect, useState } from 'react'
import { Modal, Switch } from 'antd'
import './CookieSetting.css'


export default function CookieSetting({
  isOpen, setIsOpen, setCookiePolicy
}) {

  const [setting, setSetting] = useState({
    functionality: false,
    performance: false,
    targeting: false,
  })

  const onAccept = () => {
    setSetting({
      functionality: true,
      performance: true,
      targeting: true,
    })
  }
  const onReject = () => {
    setSetting({
      functionality: false,
      performance: false,
      targeting: false,
    })
  }

  useEffect(() => {
    const cookieSetting = localStorage.getItem('cookieSetting')
    if (cookieSetting) {
      setSetting(JSON.parse(cookieSetting))
    }
  }, [])

  return (
    <Modal
      // title="Get the app"
      open={isOpen}
      onCancel={() => {
        setIsOpen(false)
      }}
      centered
      footer={null}
      forceRender
      destroyOnClose={false}
      width={600}
      zIndex={99999}
      title="Manage cookies"
    >
      <div className='cookie-wrapper font-light'>
        <p className='cookie-content-desc'>
          Cookies and similar technologies collect certain information about how you’re using our website.
          Some of them are essential, and without them you wouldn’t be able to use Fitzba.
          But others are optional, and you get to choose whether we use them or not.
          <br />Hungry for more? <a href={'/cookie-policy'} className='underline' target='_blank' rel="noreferrer">Read our full Cookie Policy.</a></p>
        <div className='flex items-center justify-end gap-4'>
          <button className={`cookie-btn cookie-btn-reject`} onClick={onReject}>Reject All</button>
          <button className={`cookie-btn cookie-btn-accept`} onClick={onAccept}>Accept All</button>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='border-b border-[#D9D9D9] pb-3'>
            <h4 className='font-bold'>Essential Cookies</h4>
            <p>These cookies are necessary for the basic functionality of the website. Without them, you wouldn’t be able to access certain features, such as logging in, making purchases, or navigating securely.</p>
          </div>
          <div className='border-b border-[#D9D9D9] pb-3'>
            <div className='flex justify-between items-center'>
              <h4 className='font-bold'>Functionality Cookies</h4>
              <Switch
                checked={setting.functionality}
                onChange={(checked) => setSetting({ ...setting, functionality: checked })}
              />
            </div>
            <p>These cookies remember your preferences (e.g., language settings, region, etc.) and help us provide a more personalized experience. For example, we might remember your login details so you don’t have to re-enter them each time you visit.</p>
          </div>
          <div className='border-b border-[#D9D9D9] pb-3'>
            <div className='flex justify-between items-center'>
              <h4 className='font-bold'>Performance and Analytics Cookies</h4>
              <Switch
                checked={setting.performance}
                onChange={(checked) => setSetting({ ...setting, performance: checked })}
              />
            </div>
            <p>These cookies help us understand how visitors interact with our website. We use this information to improve our site, monitor website performance, and analyze traffic. Tools like Google Analytics may be used to track your behavior anonymously.</p>
          </div>
          <div>
            <div className='flex justify-between items-center'>
              <h4 className='font-bold'>Targeting/Advertising Cookies</h4>
              <Switch
                checked={setting.targeting}
                onChange={(checked) => setSetting({ ...setting, targeting: checked })}
              />
            </div>
            <p>These cookies are used to deliver relevant advertising to you based on your interests. They track your browsing habits and allow us (and our advertising partners) to show you ads that are most likely to be of interest to you. These cookies can also be used to limit the number of times you see an ad.
            </p>
          </div>
          <button className='bg-[#E4B456] mt-3 text-white rounded-md py-2 px-5'
            onClick={() => {
              localStorage.setItem('cookieSetting', JSON.stringify(setting))
              setIsOpen(false)
              setCookiePolicy(false)
            }}
          >Save & Close</button>
        </div>
      </div>
    </Modal >
  )
}
