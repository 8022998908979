import React from 'react'
import PlanIcon from './PlanIcon'
import RetailButtons from './RetailButtons'

export default function PlanListNew({
    isMobile,
    setIsOpen,
    setIsOpenForm
}) {

    return (
        <div className='bg-[#F9EDD6] flex flex-col gap-10 p-10 m-auto text-center items-center justify-center smsize:p-1'>
            <div>
                <table className='plan-table'>
                    <tbody>
                        <tr>
                            <th rowSpan="2" className='plan-big-title'>
                            </th>
                            <th valign='top' width={200} className='plan-title'>STOREFRONT
                                PARTNERZ
                            </th>
                            <th valign='top' width={200} className='plan-title'>BRAND
                                PARTNERZ
                            </th>
                        </tr>
                        <tr>
                            <td className='text-[24px] smsize:text-[12px]'>$99/month<br />
                                <span className='whitespace-nowrap'>(per storefront)</span>
                            </td>
                            <td className='text-[24px] smsize:text-[12px]'>Let’s Chat!<br />
                                <span className='whitespace-nowrap'>Book a Call!</span>
                            </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Business Profile on Fitzba</strong><br />
                                Tell consumers about yourself<br />and the products and/or services you sell
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Gift Card button</strong><br />
                                Sell Gift Cards online<br />
                                (*fees & setup required)
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Checkout button</strong><br />
                                Accept payments<br />
                                (*fees & setup required)
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Reserve button</strong><br />
                                Accept reservations<br />
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Inventory for Retail</strong><br />
                                Manual/Upload/Live**<br />
                                (*setup required)
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Menus for Services</strong><br />
                                Manual/Upload
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Menus for Restaurants</strong><br />
                                Manual/Upload

                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Promoted items/services</strong><br />
                                Up to 12 per month
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Special Offers</strong><br />
                                Weekly Offers to drive sales
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Feature in Monthly Shopping Guides</strong><br />
                                (Holiday, Winter, Spring/Summer, Fall)

                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                        </tr>
                        <tr>
                            <th className='left'><strong>Dashboard & Reporting</strong><br />
                                Account Management & Consumer Insights
                            </th>
                            <td><PlanIcon type='yes' /></td>
                            <td><PlanIcon type='yes' /></td>
                        </tr>
                    </tbody>
                </table>
                <div className='text-left mt-3'>* Ask about Member Programs for Chambers of Commerce and Partners.</div>
            </div>
            {/* <div className='text-[20px]'>White glove account setup and one <strong className='text-[#0297A7] underline'>FREE</strong> month</div> */}
            <RetailButtons setIsOpen={setIsOpen} setIsOpenForm={setIsOpenForm} gap={
                isMobile ? '20px' : '60px'
            } />
        </div>
    )
}
