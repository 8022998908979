import React from 'react'
import DropDown from './DropDown'
import LeftAndRightBtns from './LeftAndRightBtns'
import Header from './Header'

export default function GiftGuideTemplate({
    currentPage,
    totalPage,
    handlePrevious,
    handleNext,
    index,
    setIndex,
    selectItems,
    children,
}) {
    return (
        <div className='relative m-auto flex h-auto pb-5 smsize:pb-3 w-[768px] flex-col items-center bg-white text-[#202020] shadow-lg smsize:h-full smsize:w-full smsize:overflow-hidden'>
            <LeftAndRightBtns
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                currentPage={currentPage}
                totalPage={totalPage}
            />
            <Header
                selectItems={selectItems}
                index={index}
                setIndex={setIndex}
            />
            {/* Content goes here */}
            {children}
        </div>
    )
}
