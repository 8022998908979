/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Zearch is a functional React component that renders the search functionality,
 * filters, and item listing.
 *
 * @author Yang Ming, Joshua Soke
 * @version May 09, 2023
 */

import React, { useState, useEffect } from 'react'
import './Zearch.css'
import { useSelector } from 'react-redux'
import Filter from './components/Filter'
import ItemList from './components/ItemList'
import GuessYouLike from './components/GuessYouLike'
import StoreList from './components/StoreList'
import MobileFilter from './components/MobileFilter'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Loading from '@components/Loading'
import { getFiltersFromLink } from '@utils/Helper'
import {
  initItemsFilter,
  setGotoScrollY,
  setSaveScrollY,
  setFilterSideBrands,
  setFilterSideStores,
  setCategoryListExisted,
  setStoresList,
  setIsReloadItemsList,
  setFilterList,
  setPreItemsFilter,
  setItemsList,
  setSearchFilter
} from '@reducers/contentReducer'
import { getInventoryListAlgolia } from '@services/api.service'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { useQuery } from '@tanstack/react-query'
import MobileTopFilter from './components/MobileTopFilter'
import { setIsLoadingSeeMore } from 'reducers/contentReducer'

export default function ZearchV2() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [isShowFilterDetail, setIsShowFilterDetail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const gotoScrollY = useSelector(state => state.content.gotoScrollY)
  const isMobile = useSelector(state => state.content.isMobile)
  const location = useSelector(state => state.user.location)
  const itemsFilter = useSelector(state => state.content.itemsFilter)
  // const [itemsList, setItemsList] = useState([])
  const itemsList = useSelector(state => state.content.itemsList)
  const filterList = useSelector(state => state.content.filterList)
  const seeMoreRef = React.useRef(null)

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      dispatch(setSaveScrollY(window.scrollY))
    }
    window.addEventListener('scroll', handleBeforeUnload)
    window.addEventListener('touchmove', handleBeforeUnload)
    return () => {
      window.removeEventListener('scroll', handleBeforeUnload)
      window.removeEventListener('touchmove', handleBeforeUnload)
    }
  }, [dispatch])

  const {
    isPending,
    // error,
    // data: itemsList,
  } = useQuery({
    queryKey: ['itemlist', itemsFilter, location],
    queryFn: async () => {
      console.log('fetchItemList is running', itemsFilter)
      dispatch(setPreItemsFilter(itemsFilter))
      const params = {
        ...itemsFilter,
        code: searchParams.get('code') || itemsFilter?.code,
        onlyOneStore: searchParams.get('store') ? 1 : itemsFilter?.onlyOneStore,
        stores: searchParams.get('store') ? [searchParams.get('store')] : itemsFilter?.stores,
        lat: location.lat,
        lng: location.lng,
      }
      console.log('params', params)
      const res = await getInventoryListAlgolia(params)
      console.log('itemlist', res)
      // setItemsList(res)
      // let isContained = null
      // if (itemsList.data.length !== 0 && res.data.length !== 0) {
      //   isContained = itemsList.data.find((item => item._id === res.data[0]._id))
      // }
      // if (!isContained) {
      dispatch(setItemsList({
        ...res,
        data: itemsFilter.currentPage === 1 ?
          res?.data :
          itemsList?.data.concat(res?.data),
      }))
      // }
      dispatch(setFilterList({
        keywords: res?.keywords,
        brands: res?.brands,
        stores: res?.storesExisted,
        categories: res?.categoryList,
      }))
      dispatch(setStoresList({
        data: res?.storesExisted,
        total: res?.storesExisted?.length,
      }))
      if (res?.categoriesExisted?.length > 0)
        dispatch(setCategoryListExisted(res?.categoriesExisted))
      // smooth scroll to top
      // setTimeout(() => {
      //   window.scrollTo({
      //     top: gotoScrollY,
      //     behavior: 'instant',
      //   })
      // }, 500)
      // setup
      // if (itemsFilter.currentPage === 1) {
      setTimeout(() => {
        window.scrollTo({
          top: itemsFilter.currentPage === 1 ? 0 : (gotoScrollY || 0),
          behavior: 'instant',
        })
      }, 500)
      // }
      // dispatch(setGotoScrollY(0))
      // else {
      // setTimeout(() => {
      //   window.scrollTo({
      //     top: seeMoreRef.current.offsetTop - 100,
      //     behavior: 'instant',
      //   })
      // }, 500)
      // }
      return res
    }
  })

  if (isPending && itemsList?.data.length === 0)
    return <Loading />

  async function onClickSeeMore() {
    console.log('onClickSeeMore')
    dispatch(setSearchFilter({
      type: 'currentPage',
      value: itemsFilter.currentPage + 1
    }))

  }

  return (
    <div className='content-wraper hide-scroll w-screen text-left font-lexend md:flex '>
      {!isMobile && <Filter isMobile={isMobile} />}
      {isMobile && <MobileTopFilter />}
      <div className='items-wrapper' id="zearch-items">
        <StoreList
          dispatch={dispatch}
          filterList={filterList}
          location={location}
          isShowFilterDetail={isShowFilterDetail}
          isMobile={isMobile} />
        <ItemList
          isShowFilterDetail={isShowFilterDetail}
          isMobile={isMobile}
          itemsList={itemsList}
          isLoading={isLoading}
          searchParams={searchParams}
          onClickSeeMore={onClickSeeMore}
          seeMoreRef={seeMoreRef}
        />
        {itemsList?.guessYouLike && (
          <GuessYouLike
            isShowFilterDetail={isShowFilterDetail}
            isMobile={isMobile}
            itemsList={itemsList.guessYouLike}
            isLoading={isLoading}
            searchParams={searchParams}
          />
        )}
        {/* {isMobile && <MobileFilter
          isShowFilterDetail={isShowFilterDetail}
          setIsShowFilterDetail={setIsShowFilterDetail}
          isMobile={isMobile}
        />} */}
      </div>
    </div>
  )
}
