/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * Landing page of the app
 *
 * @author Yang Ming
 * @version May 30, 2023
 */

import React, { Suspense } from 'react'
import './Landing.css'

// import WhyFitzbaSection from './components/WhyFitzbaSection'
import WhyFitzbaSectionNew from './components/WhyFitzbaSectionNew'
import HotDealProducts from './components/HotDealProducts'
// import RecommendedProducts from './components/RecommendedProducts'
import RecommendedNew from './components/RecommendedNew'
// import Benefit from './components/Benefit'
// import BrandList from './components/BrandList'
import CarouselSectionNew from './components/CarouselSectionNew'

// import PopularCategories from './components/PopularCategories'
// import LandingLocation from './components/LandingLocation'
import LandingLocationNew from './components/LandingLocationNew'
import { useParams, useSearchParams } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { getProfileByShortlink } from '@services/api.business.service'
import { useQuery } from '@tanstack/react-query'
// import Zearch from '../zearch/Zearch'
// import ZearchV2 from '../zearch/ZearchV2'
import StoreProfile from '../store/StoreProfile'
// import { setFilterStoreOnlyOne, setSearchFilter } from '@reducers/contentReducer'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import Loading from '../../components/Loading'
import FeaturedProductsNew from './components/FeaturedProductsNew'
// import GivewayTask from './components/GivewayTask'
import { ssoLoginGoogle } from '@services/api.service'
import { useNavigate } from 'react-router-dom'
import { setUserinfo } from '@reducers/userReducer'
import GiftGuideSection from './components/GiftGuideSection'
import GiftGuidePage from 'pages/gift-guide/GiftGuide'

// const NewAndFeatureProduct = React.lazy(() => import('./components/NewAndFeatureProduct'))

const NearBrandsNew = React.lazy(() => import('./components/NearBrandsNew'))
const NearStores = React.lazy(() => import('./components/NearStores'))

/**
 *
 * @returns Renders the landing page of the app
 */



export default function Landing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { city, store } = useParams()
  // const searchHistory = useSelector(state => state.user.searchHistory)
  const isMobile = useSelector(state => state.content.isMobile)
  const setting = useSelector(state => state.user.setting)
  const userinfo = useSelector(state => state.user.userinfo)
  // const itemsFilter = useSelector(state => state.content.itemsFilter)
  // const [isShowRecommended, setIsShowRecommended] = React.useState(false)
  // useEffect(() => {
  //   setIsShowRecommended(searchHistory && searchHistory.length > 0)
  // }, [searchHistory])

  React.useEffect(() => {
    const hash = window.location.hash.substring(1)
    const params = new URLSearchParams(hash)
    const access_token = params.get('access_token')
    async function loginGoogle(access_token) {
      const res = await ssoLoginGoogle({
        access_token: access_token
      })
      console.log('handleOnSuccess', res)
      dispatch(setUserinfo(res.data))
      localStorage.removeItem('tmp_id')
      navigate('/')
    }
    if (access_token) {
      loginGoogle(access_token)
    }
  }, [dispatch, navigate])

  const {
    isPending,
    data: storeProfile,
  } = useQuery({
    queryKey: ['storeProfile', city, store],
    queryFn: async () => {
      if (!city || !store) {
        return null
      }
      const res = await getProfileByShortlink(store,
        city,
        userinfo?._id,
        searchParams.get('qrcode')
      )
      return res
    },
  })

  if (isPending) {
    return <Loading />
  }

  if (city === 'cochrane-ab' && store === 'holiday-gift-guide') {
    return <GiftGuidePage />
  }

  if (storeProfile) {
    // console.log('storeProfile11111', storeProfile)
    // if (storeProfile?.name === itemsFilter?.title) {
    // dispatch(setSearchFilter({ type: 'init', value: '' }))
    // }
    dispatch(setFilterStoreOnlyOne(storeProfile?._id))
    return <StoreProfile storeId={storeProfile?._id} />
    // return <ZearchV2 />
  }

  let reorderedList = [
    'featuredProducts',
    'nearStores',
    'shopBrands',
    'hotDeals',
    'collections',
  ]
  if (setting && setting.homePageSectionOrder)
    reorderedList = setting.homePageSectionOrder

  const sectionsList = [
    {
      name: 'featuredProducts',
      content: <FeaturedProductsNew />
    },
    {
      name: 'nearStores',
      content: <NearStores />
    },
    {
      name: 'hotDeals',
      content: <HotDealProducts />
    },
    {
      name: 'shopBrands',
      content: <NearBrandsNew />
    },
    {
      name: 'collections',
      content: <RecommendedNew />
    }
  ]

  // re sort base on reorderedList
  const sortedSectionsList = sectionsList.sort((a, b) => {
    return reorderedList.indexOf(a.name) - reorderedList.indexOf(b.name)
  })

  return (
    <main className='landing-wrapper'>
      <Suspense fallback={<div>Loading...</div>}>
        <LandingLocationNew />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <CarouselSectionNew />
      </Suspense>
      {isMobile && <GiftGuideSection />}
      {/* {userinfo && <GivewayTask />} */}
      {sortedSectionsList.map((item, index) => {
        return <Suspense key={index} fallback={<div>Loading...</div>}>
          {item.content}
        </Suspense>
      })
      }
      {/* <Suspense fallback={<div>Loading...</div>}>
        <FeaturedProductsNew searchHistory={searchHistory} setIsShowRecommended={() => { }} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <NearStores />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <NearBrandsNew />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <NearProducts searchHistory={searchHistory} setIsShowRecommended={() => { }} />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <RecommendedNew />
      </Suspense> */}
      <WhyFitzbaSectionNew isMobile={isMobile} />
    </main>
  )
}
