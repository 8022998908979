import React from 'react'
import { Modal } from 'antd'
import { Descriptions } from 'antd'

export default function OfferDetail({
    isOpen,
    setIsOpen,
    offer
}) {
    console.log('offer', offer)
    let valueText = 'N/A'
    switch (offer?.type) {
        case 'Discount':
            valueText = `${offer?.value}% off`
            break
        case 'Cash':
            valueText = `$${offer?.value} off`
            break
        default:
            break
    }

    const items = [
        {
            key: '1',
            label: 'Name',
            children: offer?.title,
            span: 1,
        },
        {
            key: '2',
            label: 'Expries at',
            children: offer?.userPromotion?.endedAt ? new Date(offer?.userPromotion?.endedAt).toISOString().split('T')[0] : 'N/A',
        },
        {
            key: '3',
            label: 'Type',
            children: offer?.type,
        },
        {
            key: '4',
            label: 'Discount',
            children: valueText,
        },
        {
            key: '5',
            label: 'Promo Code',
            children: offer?.promoCode,
        },
        {
            key: '6',
            label: 'Minimum Amount',
            children: offer?.minAmount ? `On purchases of $${offer?.minAmount} or more` : 'N/A',
        },
        {
            key: '10',
            label: 'Description',
            children: offer?.description,
            span: 1,
        },
        {
            key: '11',
            label: 'Store',
            children: <div>
                <img src={offer?.business?.logo} alt='store' className='w-[90px] h-[90px] rounded-full border-4 border-[#eee]' />
                <p>{offer?.business?.name}</p>
            </div>,
        },
    ]

    return (
        <Modal
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            centered
            width={600}
            footer={null}
            title={'Offer Detail'}
        >
            <Descriptions bordered items={items} column={1} />
        </Modal>
    )
}
