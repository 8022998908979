import React, { useEffect } from 'react'
import './GiftGuide.css'
import GiftGuidePage1 from './GiftGuide1'
import GiftGuidePage2 from './GiftGuide2'
// import GiftGuidePageStores from './GiftGuide2Stores'
// import { Carousel } from 'antd'
import SwipeableViews from 'react-swipeable-views'
import { getGiftGuideStoreList } from '@services/api.business.service'
import GiftGuideItemNew from './GiftGuideItemNew'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import GiftGuideContest from './GiftGuideContest'

export default function GiftGuide() {
  const { store } = useParams()
  console.log('store', store)
  const [index, setIndex] = React.useState(0)
  const [stores, setStores] = React.useState([])
  const [totalPage, setTotalPage] = React.useState(2)
  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getGiftGuideStoreList({
          checkIsGiftGuideActivated: true,
        })
        console.log('GiftGuideItem', result)
        setStores(result?.data)
        setTotalPage(result?.data?.length + 3)
        if (store) {
          const findIndex = result?.data?.findIndex(
            item => item?.shortLink === store,
          )
          if (findIndex !== -1) {
            setIndex(findIndex + 2)
          }
        }
      } catch (err) {
        console.log(err.data?.message || 'get gift guide item error')
      }
    }
    fetchData()
  }, [store])

  const handlePrevious = () => {
    if (index > 0) setIndex(index - 1)
  }

  const handleNext = () => {
    if (index < totalPage - 1) setIndex(index + 1)
  }
  // const selectItems =[]
  const selectItems = stores?.map((store, index) => ({
    key: index + 2,
    id: store?._id,
    label: <Link onClick={() => setIndex(index + 2)}>{store?.name}</Link>,
  }))

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>2024 Holiday Gift Guide | Fitzba</title>
        </Helmet>
      </HelmetProvider>
      <SwipeableViews
        enableMouseEvents
        index={index}
        onChangeIndex={setIndex}
        resistance={true} // 更强的滑动响应
        containerStyle={{ touchAction: 'pan-y' }} // 限制只响应纵向滑动
      >
        <GiftGuidePage1
          currentPage={1}
          totalPage={stores?.length + 2}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
        <GiftGuidePage2
          currentPage={2}
          totalPage={stores?.length + 2}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
        {stores?.map((store, index) => (
          <GiftGuideItemNew
            key={index}
            store={store}
            currentPage={index + 3}
            totalPage={totalPage}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            selectItems={selectItems}
            index={index}
            setIndex={setIndex}
          />
        ))}
        <GiftGuideContest
          currentPage={totalPage - 1}
          totalPage={totalPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          selectItems={selectItems}
          index={index}
          setIndex={setIndex}
        />
      </SwipeableViews>
    </>
  )
}
