import React from 'react'
import './GiftGuide.css'
import { getStoreLink } from '@utils/Helper'
import SeeMoreDetails from './components/SeeMoreDetails'
import Logo from './components/Logo'
import Address from './components/Address'
import PhoneNumber from './components/PhoneNumber'
import Price from './components/Price'
import GiftCardButton from './components/GiftCardButton'
import ShopNowButton from './components/ShopNowButton'
import ItemImage from './components/ItemImage'
import ItemTitle from './components/ItemTitle'
import GiftGuideTemplate from './components/GiftGuideTemplate'

function GiftGuideItem({ item }) {
  return (
    <div className='flex w-full items-start justify-end'>
      <div className='flex w-[50%] flex-col justify-end gap-3 pr-5 text-right text-[#202020] smsize:w-full'>
        <ItemTitle name={item.name} />
        <Price
          salePrice={item?.salePrice}
          isRight={true}
          priceRange={item?.gift_guide_price_range}
        />
        <p
          className='leading-5 line-clamp-5'
          dangerouslySetInnerHTML={{ __html: item?.gift_guide_text || item?.description }}
        />
        <SeeMoreDetails item_id={item?._id} />
      </div>
      {/* <div className='flex w-[50%] justify-end overflow-hidden smsize:w-full'>
        <ItemImage
          image={item?.image}
          name={item.name}
          width='100%'
          height='260px'
          extraCss={'w-full smsize:w-full smsize:h-[200px]'}
        />
        <div className='w-[50px] flex-shrink-0 bg-[#520a0a] smsize:hidden'>
          {' '}
        </div>
      </div> */}
      <table className='m-0 w-[50%] p-0 smsize:w-full'>
        <tbody>
          <tr className='m-0 p-0'>
            <td className='m-0 p-0'>
              <ItemImage
                image={item?.image}
                name={item.name}
                width='100%'
                height='260px'
                extraCss={'w-full smsize:w-full smsize:h-[200px]'}
              />
            </td>
            <td className='w-[50px] bg-[#520a0a] smsize:w-[20px]'></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default function GiftGuide3b({
  business,
  currentPage,
  totalPage,
  handlePrevious,
  handleNext,
  selectItems,
  index,
  setIndex,
}) {
  const storeLink = getStoreLink(
    business?.shortLink,
    business?.city,
    business?.province,
    business?.name,
    'gift-guide',
  )
  return (
    <GiftGuideTemplate
      currentPage={currentPage}
      totalPage={totalPage}
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      index={index}
      setIndex={setIndex}
      selectItems={selectItems}
    >
      <div className='relative flex flex-col gap-3 p-5 pb-14 smsize:pb-10'>
        <img
          className='absolute bottom-[-20px] right-10 w-[350px] opacity-50 smsize:right-0 smsize:w-[250px]'
          src='/gift-guide/snow-leaf4.png'
          alt='Snow flake'
        />
        <div className='flex items-stretch justify-between gap-3 smsize:flex-col'>
          <div className='flex w-full flex-1 flex-col justify-between gap-5'>
            <div className='flex items-center gap-3'>
              <Logo
                logo={business?.logo}
                name={business?.name}
                storeLink={storeLink}
              />
              <h1 className='z-10 whitespace-normal break-normal break-words text-left text-[40px] font-bold leading-10 text-[#541b1f] smsize:w-full smsize:text-[30px]'>
                {business?.name}
              </h1>
            </div>
            <div className='flex flex-col gap-1 text-left'>
              <Address business={business} />
              <PhoneNumber business={business} />
            </div>
            <div className='flex w-full items-center justify-start gap-3'>
              <GiftCardButton
                giftCardLink={business?.giftCardLink}
                storeLink={storeLink}
                bgColor='#520a0a'
                borderColor='#fffdf6'
                textColor='#fffdf6'
                extraCss={'px-3'}
              />
              <ShopNowButton
                storeLink={storeLink}
                bgColor='#fffdf6'
                borderColor='#520a0a'
                textColor='#520a0a'
                extraCss={'px-3'}
              />
            </div>
          </div>
          <div
            className='max-h-[160px] flex-1 pt-5 text-left text-[20px] font-light leading-7 line-clamp-5 smsize:w-full smsize:text-[16px] smsize:leading-5 smsize:line-clamp-5'
            dangerouslySetInnerHTML={{
              __html: business?.shortDesc || business?.description,
            }}
          />
        </div>
      </div>
      <div className='flex py-3'></div>
      <div className='flex w-full flex-col justify-end gap-5'>
        {business?.items?.map((item, index) => {
          return <GiftGuideItem key={index} item={item} />
        })}
      </div>
    </GiftGuideTemplate>
  )
}
