/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector } from 'react-redux'
import './Products.css'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getStoreProducts } from '@services/api.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ItemCard from '../../zearch/components/ItemCard'
import ItemCardMobile from '../../zearch/components/ItemCardMobile'
import SectionTitle from './SectionTitle'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function GiftGuideProducts({
    business,
    isMobile,
}) {
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)


    const {
        isPending,
        // error,
        data: products,
    } = useQuery({
        queryKey: ['getStoreProducts', 'gift-guide', location, business?._id],
        queryFn: async () => {
            const params = {
                lat: location?.lat,
                lng: location?.lng,
                type: 'gift-guide',
                storeid: business?._id,
            }
            const res = await getStoreProducts(params)
            return res
        },
    })

    if (isPending)
        return <LoadingSection text='Loading gift guide items' height='200px' />

    return (
        <section className="w-full max-w-[1280px] relative flex flex-col items-start">
            <SectionTitle title={'GIFT GUIDE ITEMS'} />
            <div className="w-full flex gap-5 text-left flex-wrap smsize:gap-3" ref={productListRef}>
                {products?.data?.map((item, index) => {
                    if (isMobile)
                        return <ItemCardMobile
                            key={index}
                            item={item}
                            userinfo={userinfo}
                            handleAddToCart={addToCart}
                        />
                    return <ItemCard key={index} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                })}
                {products?.data?.length === 0 &&
                    <div className='w-full text-center text-gray-500'>No items found</div>
                }
            </div>
        </section>
    )
}
