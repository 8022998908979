import React from 'react'
import { Link } from 'react-router-dom'

export default function ItemPickupTags({
  business,
  itemLink
}) {
  return (
    <div className='flex gap-1 mt-2 text-[10px] font-light text-[#0B6186] flex-wrap'>
      <Link
        to={itemLink + '&pickup=true'}
        className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
      >
        pick-up
      </Link>
      {business?.isDelivery && (
        <span
          className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
        >
          delivery
        </span>
      )}
      {business?.isShipping && (
        <span
          className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
        >
          shipping
        </span>
      )}
      {business?.isReserve && (
        <span
          className='bg-[#E4F2F3] rounded-sm p-1 cursor-pointer'
        >
          reservation
        </span>
      )}
    </div>
  )
}
