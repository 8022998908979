/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * The BlogItem component renders a single blog post item.
 * It displays the blog post's picture, title, date, and description.
 * Each blog post item is a link that navigates to the full content of the blog post.
 * The component receives the blog post data and a flag indicating whether it's a side item as props.
 *
 * @author Yang Ming
 * @version October 1st, 2023 
 */

import './BlogItem.css'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'


export default function BlogItem({
    currentRow,
    isSide
}) {
    if (!currentRow) return null
    const linkTo = `/blog/${currentRow?.seo?.title}`

    return (

        <div className='blog-item' style={{ flexDirection: isSide ? 'column' : null }}>
            {currentRow?.picture &&
                <Link to={linkTo} className='flex-1'><img src={currentRow?.picture} alt={currentRow?.title} /></Link>
            }
            <div className='blog-item-content flex-1'>
                <Link to={linkTo} className='text-[16px] leading-5 font-bold'>{currentRow?.title}</Link>
                <p className='date'>{moment(currentRow?.updatedAt).format('MM/DD/YYYY HH:mm')}</p>
                <p className='desc ql-editor' dangerouslySetInnerHTML={{ __html: currentRow?.description }}></p>
                <p></p>
                <p><Link to={linkTo}>Read it</Link></p>
            </div>
        </div>
    )
}
